interface Number {
  toPrice(decimals?: number): string;
}

Number.prototype.toPrice = function(decimals = 2) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
  return formatter.format(this);
};
