interface String {
  toISO8601Date(value?: string): string;
}
String.prototype.toISO8601Date = function(value?: string) {
  if (this || value) {
    let parts = (value || this).split('/');
    if (Number.parseInt(parts[0]) < 10) {
      parts[0] = `0${parts[0]}`;
    }
    if (Number.parseInt(parts[1]) < 10) {
      parts[1] = `0${parts[1]}`;
    }
    return `${parts[2]}-${parts[0]}-${parts[1]}`;
  } else return '0001-01-01';
};
